<template>
<div>
  <div v-if="!$root.mobile">
    <v-row
      class="py-0 my-0"
      style="height:calc(100vh - 160px);position: relative"
    >
      <v-col class="" cols="3">
        <div style="height:calc(100vh - 160px)" class="white rounded-lg">
          <v-list class="rounded-lg">
            <v-subheader style=" font-size:1.2rem" class="primary--text"
              >Manage your schedule</v-subheader
            >
            <v-list-item-group v-model="tab">
              <v-list-item>
                <v-list-item-icon>
                  <unicon name="clock" fill="#0971ce" height="20"></unicon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style=" font-size:1.2rem">
                    {{ $t("SCH.avail") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <unicon name="sign-alt" fill="#0971ce" height="20"></unicon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style=" font-size:1.2rem">
                    {{ $t("SCH.days_off") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-col>
      <v-col cols="9">
        <perfect-scrollbar
          class="scroll"
          style="height:calc(100vh - 160px);position: relative"
        >
          <v-alert dense border="left" type="warning">
            {{ $t("SCH.note") }}
          </v-alert>
          <v-card v-if="tab == 0" flat class="rounded-lg">
            <v-toolbar flat>
              <v-toolbar-title class="text-primary">
                {{ $t("SCH.avail") }} <small>{{ $t("SCH.avail_des") }}</small>
              </v-toolbar-title>
              <v-spacer />
              <v-btn
                :disabled="remaningDays.length == 0"
                rounded
                color="primary"
                elevation="0"
                @click="addnewavail = true"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $t("SCH.add") }}
              </v-btn>
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="availability"
              :loading="!availLoaded"
              flat
              :locale="this.$root.lang"
              class="rounded-lg"
              :no-data-text="$t('SCH.avail_empty')"
            >
              <!-- body -->
              <template v-slot:item.days="{ item }">
                <v-chip-group active-class="primary--text" column>
                  <v-chip
                    v-for="day in item.days"
                    :key="day + 'd'"
                    label
                    color="primary"
                    dark
                    class="pushx"
                  >
                    {{ dayofweek(day) }}
                  </v-chip>
                </v-chip-group>
              </template>
              <template v-slot:item.from="{ item }">
                <div class="font-size-lg font-weight-bolder">
                  {{
                    ("2020-01-01T" + item.from + "Z")
                      | moment("timezone", $util.getTimezone(), "hh:mm A")
                  }}
                </div>
              </template>
              <template v-slot:item.to="{ item }">
                <div class="font-size-lg font-weight-bolder">
                  {{
                    ("2020-01-01T" + item.to + "Z")
                      | moment("timezone", $util.getTimezone(), "hh:mm A")
                  }}
                </div>
              </template>
              <template v-slot:item.modified_on="{ item }">
                <div class="font-size-lg font-weight-bolder">
                  {{
                    item.modified_on
                      | moment(
                        "timezone",
                        $util.getTimezone(),
                        "ddd, MMM Do YYYY hh:mm A"
                      )
                  }}
                  <br />{{ item.modified_on | moment("from", "now") }}
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <div style="display: flex;justify-content: flex-end;">
                  <Edit
                    @submit-edit="submitEdit"
                    :allowedStep="allowedStep"
                    :doctorAvails="item"
                  />
                  <v-btn
                    @click="
                      showdeletewarning = true;
                      todelete = item;
                    "
                    icon
                    elevation="0"
                  >
                    <unicon
                      name="trash-alt"
                      fill="#f64e60"
                      height="20"
                    ></unicon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card>
          <v-card v-if="tab == 1" flat class="rounded-lg">
            <v-toolbar flat>
              <v-toolbar-title class="text-primary">
                {{ $t("SCH.days_off") }}
                <small>{{ $t("SCH.days_off_des") }}</small>
              </v-toolbar-title>
              <v-spacer />
              <v-btn
                rounded
                color="primary"
                elevation="0"
                @click="addnewexception = true"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $t("SCH.add") }}
              </v-btn>
            </v-toolbar>
            <div
              v-if="!excepLoaded"
              class="text-center mt-5"
              style="width: 100%; margin-right: auto; margin-left: auto"
            >
              <b-spinner
                variant="primary"
                type="grow"
                label="Spinning"
              ></b-spinner>
            </div>
            <div id="exceptions" v-if="excepLoaded">
              <v-list v-if="exceptions.length > 0">
                <template v-for="(excep, idx) in exceptions">
                  <v-list-item :key="excep.id">
                    <v-list-item-content>
                      <v-list-item-title
                        ><span class="font-size-h6"
                          >{{ excep.date | moment("dddd, MMMM Do YYYY") }} -
                          <small>{{
                            excep.date | moment("from", "now")
                          }}</small></span
                        ></v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action v-if="!isBeforeToday(excep.date)">
                      <a
                        @click="deleteItem(excep.id, 'exception')"
                        class="btn btn-sm btn-icon btn-light-danger"
                      >
                        <em class="far fa-trash-alt"></em>
                      </a>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider
                    v-if="idx + 1 < exceptions.length"
                    :key="idx"
                  ></v-divider>
                </template>
              </v-list>

              <div v-else class="text-center text-muted" style="width: 100%">
                {{ $t("SCH.excep_empty") }}
              </div>
            </div>
          </v-card>
        </perfect-scrollbar>
      </v-col>
    </v-row>
    

    <!-- end exceptions modal -->
  </div>
  <div v-else>
    <div class="mb-3" style="display:flex; justify-content:center">
      <v-btn-toggle
        v-model="tab"
        mandatory
        rounded
        
        class="mx-0 blue primary--text"
      >
        <v-btn active-class="activebtn" width="46vw" class="primary--text">
          {{ $t("SCH.avail") }}
        </v-btn>
        <v-btn active-class="activebtn" width="46vw" class="primary--text">
          {{ $t("SCH.days_off") }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <div v-if="tab == 0" flat class="transparent pb-10">
      <div v-for="item in availability" :key="item.id" class="mb-2 listitem">
        <v-list-item three-line style="min-height:80px">
          <v-list-item-content>
            <v-list-item-title>
              <v-chip
                v-for="day in item.days"
                :key="day + 'd'"
                label
                color="primary"
                dark
                class="pushx"
              >
                {{ dayofweek(day) }}
              </v-chip>
            </v-list-item-title>
            <v-list-item-subtitle>
              From:
              {{
                ("2020-01-01T" + item.from + "Z")
                  | moment("timezone", $util.getTimezone(), "hh:mm A")
              }}
              To:
              {{
                ("2020-01-01T" + item.to + "Z")
                  | moment("timezone", $util.getTimezone(), "hh:mm A")
              }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{
                item.modified_on
                  | moment(
                    "timezone",
                    $util.getTimezone(),
                    "ddd, MMM Do YYYY hh:mm A"
                  )
              }}{{ item.modified_on | moment("from", "now") }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <div style="display: flex;justify-content: flex-end; flex-direction:column">
              <Edit
                @submit-edit="submitEdit"
                :allowedStep="allowedStep"
                :doctorAvails="item"
              />
              <v-btn
                @click="
                  showdeletewarning = true;
                  todelete = item;
                "
                icon
                elevation="0"
              >
                <unicon name="trash-alt" fill="#f64e60" height="20"></unicon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
      </div>

      <v-btn
        :disabled="remaningDays.length == 0"
        fab
        color="primary"
        elevation="0"
        fixed
        bottom
        right
        large
        :class="$root.tabspadding > 0 ? 'mb-25' : 'mb-12'"
        @click="addnewavail = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <div v-if="tab == 1" flat class="rounded-lg">

      <div
        v-if="!excepLoaded"
        class="text-center mt-5"
        style="width: 100%; margin-right: auto; margin-left: auto"
      >
        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
      </div>
      <div id="exceptions" v-if="excepLoaded">
        <v-list class="transparent" v-if="isExcep" >
          <template v-for="(excep) in exceptions">
            <v-list-item :key="excep.id" style="height:70px" class="mb-2 listitem">
              <v-list-item-content>
                <v-list-item-title
                  ><span class="font-size-h6"
                    >{{ excep.date | moment("dddd, MMMM Do YYYY") }} -
                    </span
                  ></v-list-item-title
                >
                <v-list-item-subtitle>
                  {{
                      excep.date | moment("from", "now")
                    }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="!isBeforeToday(excep.date)">
                <a
                  @click="deleteItem(excep.id, 'exception')"
                  class="btn btn-sm btn-icon btn-light-danger"
                >
                  <em class="far fa-trash-alt"></em>
                </a>
              </v-list-item-action>
            </v-list-item>
           
          </template>
        </v-list>

        <div v-else class="text-center text-muted" style="width: 100%">
          {{ $t("SCH.excep_empty") }}
        </div>
      </div>
      <v-btn
        :disabled="remaningDays.length == 0"
        fab
        color="primary"
        elevation="0"
        fixed
        bottom
        right
        large
        :class="$root.tabspadding > 0 ? 'mb-25' : 'mb-12'"
        @click="addnewexception = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
  <v-dialog v-model="showdeletewarning" max-width="290" hide-overlay>
      <v-card>
        <v-card-title class="headline">
          Confirm Delete
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete this item?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary darken-1"
            text
            @click="
              showdeletewarning = false;
              todelete = null;
            "
          >
            {{ $t("APPOINTMENTS.add.concentalert.close") }}
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="
              showdeletewarning = false;
              deleteItem(todelete.id, 'availability');
              todelete = null;
            "
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- availablity modal -->
    <v-dialog v-model="addnewavail" max-width="490" hide-overlay>
      <v-card class="px-5 py-3 m-0">
        <v-card-title class="headline">
          {{ availModalTitle }}
        </v-card-title>

        <div>
          <v-form v-model="valid" ref="availabilityForm">
            <!-- <span class="font-size-h6 mr-5"> Select days of week </span> -->
            <v-autocomplete
              v-model="selectedDays"
              :items="remaningDays"
              label="Select"
              multiple
              chips
              hint="please select at least one day"
              persistent-hint
              prepend-icon="mdi-calendar-today"
              :rules="selectedDaysValidation"
            ></v-autocomplete>
            <!-- from -->
            <v-dialog
              :retain-focus="false"
              ref="dialog1"
              v-model="modal_from"
              :return-value.sync="from_time"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="from_time"
                  label="Starting From"
                  prepend-icon="flaticon2-time"
                  :rules="[(v) => !!v || 'please select start time']"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="modal_from"
                v-model="from_time"
                :max="to_time"
                full-width
                :allowed-minutes="allowedStep"
                :return-value.sync="from_time"
                @click:minute="$refs.dialog1.save(from_time)"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal_from = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog1.save(from_time)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
            <!-- to -->
            <v-dialog
              :retain-focus="false"
              ref="dialog2"
              v-model="modal_to"
              :return-value.sync="to_time"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="to_time"
                  label="To"
                  prepend-icon="flaticon2-time"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'please select end time']"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="modal_to"
                v-model="to_time"
                :min="from_time"
                full-width
                :allowed-minutes="allowedStep"
                @click:minute="$refs.dialog2.save(to_time)"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal_to = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog2.save(to_time)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-form>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary darken-1" text @click="addnewavail = false">
            {{ $t("APPOINTMENTS.add.concentalert.close") }}
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="
              submit();
              addnewavail = false;
            "
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end availablity modal -->

    <!-- exceptions modal -->
    <v-dialog v-model="addnewexception" max-width="490" hide-overlay>
      <v-card class="px-5 py-3 m-0">
        <v-card-title class="headline">
          {{ availModalTitle }}
        </v-card-title>
        <div>
          <v-date-picker
            :min="new Date().toISOString().substr(0, 10)"
            v-model="excepdate"
            :landscape="!$root.mobile"
            :allowed-dates="blockexcepdates"
            full-width
          ></v-date-picker>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary darken-1" text @click="addnewexception = false">
            {{ $t("APPOINTMENTS.add.concentalert.close") }}
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="
              submitException();
              addnewexception = false;
            "
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>

<script>
// import moment from 'moment-timezone';
import Edit from "./comp/schedule/Edit";
import ApiService from "@/core/services/api.service";

export default {
  name: "schedule",
  components: { Edit },
  data() {
    return {
      tab: 0,
      headers: [
        { text: "Days", value: "days" },
        { text: "From", value: "from" },
        { text: "To", value: "to" },
        { text: "Modified On", value: "modified_on" },
        { text: "Actions", value: "actions", align: "right" },
      ],
      todelete: null,
      showdeletewarning: false,
      addnewavail: false,
      addnewexception: false,
      weekdays: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
      edit: false,
      dialog: false,
      valid: false,
      availLoaded: false,
      availability: [],
      remaningDays: [],
      selectedDays: [],
      selectedDaysValidation: [
        (v) => !!v.length || "please select at least one day",
      ],
      isAvailable: false,
      excepLoaded: false,
      isExcep: false,
      exceptions: [],
      from_time: null,
      to_time: null,
      menu2: false,
      modal_from: false,
      modal_to: false,
      availModalTitle: this.$t("SCH.availMoalNew"),
      excepModalTitle: this.$t("SCH.excepMoalNew"),
      selectedAvailDays: [],
      excepdate: new Date().toISOString().substr(0, 10),
      error: "",
    };
  },
  computed: {},
  mounted() {
    this.$root.title = this.$t("MENU.schedule");
    this.getAvailability();
    this.getExceptions();
  },
  destroyed() {},
  methods: {
    blockexcepdates(val)
    {
      console.log("block exceptions",val)
      return this.exceptions.findIndex((i) => { return i.date == val}) == -1
    },
    allowedStep: (m) =>
      m % parseInt(JSON.parse(localStorage.SETTINGS).call_duration) === 0,
    async getAvailability() {
      try {
        console.log("doctor id is before check", this.$doctor.getCurrentDoctor());
        
        const { data } = await ApiService.get(
          "items",
          "doctor_availability?fields=id,days,from,to,modified_on&filter[doctor]=" +
            this.$doctor.getCurrentDoctor().id
        );
        var weekdays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
        this.availability = data.data;
        console.log(this.availability);
        if (data.data.length != 0) {
          this.isAvailable = true;

          for (let avail of data.data) {
            weekdays = weekdays.filter((d) => !avail.days.includes(d));
          }
        }
        this.remaningDays = weekdays;
        this.availLoaded = true;
      } catch (error) {
        this.error = error;
        console.log(error);
      }
    },
    
    async getExceptions() {
      this.excepLoaded = false;
      try {
        const { data } = await ApiService.get(
          "items",
          "doctor_exception?fields=id,date&filter[doctor]=" + this.$doctor.getCurrentDoctor().id
        );
        this.exceptions = data.data;
        ////console.log(this.exceptions);
        if (data.data.length != 0) {
          this.isExcep = true;
        }
        this.excepLoaded = true;
      } catch (error) {
        this.error = error;
        console.log(error);
      }
      // var userInfo = JSON.parse(localStorage.userInfo).id;
      // await ApiService.get(
      //   'items',
      //   'doctor_exception?fields=date&filter[doctor]=' + userInfo
      // )
      //   .then(({ data }) => {
      //     this.exceptions = data.data;
      //     ////console.log(this.exceptions);
      //     if (data.data.length != 0) {
      //       this.isExcep = true;
      //     }
      //     this.excepLoaded = true;
      //   })
      //   .catch((error) => {
      //     this.error = error;
      //   });
    },

    changeTimeFormat(utcTime) {
      var time = this.utcToTimezone(utcTime);

      var t = time.split(":");
      var h = parseInt(t[0]);
      var toReturn = "";
      if (h < 12) {
        if (h == 10 || h == 11) {
          toReturn += h;
        } else {
          toReturn += "0" + h;
        }
        toReturn += ":" + t[1] + " AM";
      } else {
        h = h - 12;

        if (h == 10 || h == 11) {
          toReturn += h;
        } else {
          toReturn += "0" + h;
        }
        toReturn += ":" + t[1] + " PM";
      }
      return toReturn;
    },
    dayofweek(day) {
      if (day == "sat") {
        return "Saturday";
      } else if (day == "sun") {
        return "Sunday";
      } else if (day == "mon") {
        return "Monday";
      } else if (day == "tue") {
        return "Tuesday";
      } else if (day == "wed") {
        return "Wednesday";
      } else if (day == "thu") {
        return "Thursday";
      } else if (day == "fri") {
        return "Friday";
      }
    },
    getTimeZone() {
      return localStorage.timezone;
    },
    utcToTimezone(time) {
      var tz_time = this.$moment
        .utc(new Date().toISOString().substr(0, 10) + " " + time)
        .tz(this.getTimeZone())
        .format("HH:mm:00")
        .toString();
      return tz_time;
    },

    async submit() {
      try {
        if (this.$refs.availabilityForm.validate()) {
          this.from_time = this.$moment
            .tz(
              new Date().toISOString().substr(0, 10) + " " + this.from_time,
              this.getTimeZone()
            )
            .utc()
            .format("HH:mm:00")
            .toString();
          console.log("calculated by devman", this.from_time);
          this.to_time = this.$moment
            .tz(
              new Date().toISOString().substr(0, 10) + " " + this.to_time,
              this.getTimeZone()
            )
            .utc()
            .format("HH:mm:00")
            .toString();

          const payload = {
            days: this.selectedDays,
            from: this.from_time,
            to: this.to_time,
            doctor: this.$doctor.getCurrentDoctor().id,
          };
          console.log("payload", payload);
          this.availLoaded = false;
          await ApiService.post("items/doctor_availability", payload);
          this.availLoaded = true;
          this.selectedDays = [];
          this.from_time = null;
          this.to_time = null;
          this.$refs.availabilityForm.resetValidation();
          this.toast(
            "b-toaster-top-center",
            "success",
            "new availability added successfully"
          );
          this.getAvailability();
        } else {
          this.toast(
            "b-toaster-top-center",
            "danger",
            "please fill all required fields"
          );
          console.log("not valid");
        }
      } catch (error) {
        this.availLoaded = true;
        this.toast("b-toaster-top-center", "danger", error);
        console.log(error);
      }
    },
    async submitException() {
      try {
        //var userInfo = JSON.parse(localStorage.userInfo).id;
        const payload = {
          date: this.excepdate,
          doctor: this.$doctor.getCurrentDoctor().id,
        };
        this.excepLoaded = false;
        await ApiService.post("items/doctor_exception", payload);
        this.excepLoaded = true;
        this.excepdate = new Date().toISOString().substr(0, 10);
        this.toast(
          "b-toaster-top-center",
          "success",
          "new exception added successfully"
        );
        this.getExceptions();
      } catch (error) {
        this.excepLoaded = true;
        this.toast("b-toaster-top-center", "danger", error);
        console.log(error);
      }
    },
    async submitEdit(payload) {
      try {
        this.availLoaded = false;
        await ApiService.update(
          "items/doctor_availability",
          payload.id,
          payload
        );
        this.availLoaded = true;
        this.toast(
          "b-toaster-top-center",
          "success",
          "availability updated successfully"
        );
        this.getAvailability();
        // console.log('valid', payload);
      } catch (error) {
        this.availLoaded = true;
        this.toast("b-toaster-top-center", "danger", error);
        console.log(error);
      }
    },
    async deleteItem(id, type) {
      try {
        if (type === "availability") {
          this.availLoaded = false;
          await ApiService.delete(`items/doctor_availability/${id}`);
          this.availLoaded = true;
          this.getAvailability();
        } else if (type === "exception") {
          this.excepLoaded = false;
          await ApiService.delete(`items/doctor_exception/${id}`);
          this.excepLoaded = true;
          this.getExceptions();
        }

        this.toast(
          "b-toaster-top-center",
          "success",
          "record deleted successfully"
        );
      } catch (error) {
        this.toast("b-toaster-top-center", "danger", error);
        console.log(error);
      }
    },
    toast(toaster, v, msg, append = false) {
      this.counter++;
      this.$bvToast.toast(msg, {
        title: `Alert`,
        toaster: toaster,
        solid: true,
        variant: v,
        appendToast: append,
      });
    },
    isBeforeToday(date) {
      return this.$moment(date).isBefore(this.$moment());
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
.v-application--wrap {
  min-height: 0px !important;
}

.activebtn{
  border-style: solid;
  border-color: #d2e1f2 !important;
  background: white !important;
  border-width: 4px !important;
  color: #0971ce !important;
}

</style>
<style scoped>
.theme--light.v-btn-toggle:not(.v-btn-toggle--group)  >>>  .v-btn.v-btn
{
  border-color: #d2e1f2 !important;
  border-width: 0px
}

.v-btn:before
{
  background-color: transparent !important;
}
</style>
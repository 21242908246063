<template>
  <div>
    <v-dialog
      :retain-focus="false"
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs"
          v-on="on"
          @click="reloadData" icon elevation="0">
                  <unicon
                    name="edit-alt"
                    fill='#0971CE'
                    height="20"
                  ></unicon>
                </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Edit Availability </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="valid" ref="editForm">
              <v-row>
                <!-- from -->

                <v-col cols="12" sm="6">
                  <v-dialog
                    :retain-focus="false"
                    ref="dialog1"
                    v-model="modal_from"
                    :return-value.sync="from_time"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="from_time"
                        label="Starting From"
                        prepend-icon="flaticon2-time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        :rules="[v => !!v || 'please select start time']"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="modal_from"
                      v-model="from_time"
                      :max="to_time"
                      full-width
                      :allowed-minutes="allowedStep"
                      :return-value.sync="from_time"
                      @click:minute="$refs.dialog1.save(from_time)"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal_from = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog1.save(from_time)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>

                <v-spacer></v-spacer>
                <!-- to -->
                <v-col cols="12" sm="6">
                  <v-dialog
                    :retain-focus="false"
                    ref="dialog2"
                    v-model="modal_to"
                    :return-value.sync="to_time"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="to_time"
                        label="To"
                        prepend-icon="flaticon2-time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        :rules="[v => !!v || 'please select end time']"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="modal_to"
                      v-model="to_time"
                      :min="from_time"
                      full-width
                      :allowed-minutes="allowedStep"
                      @click:minute="$refs.dialog2.save(to_time)"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal_to = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog2.save(to_time)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="selectedDays"
                    :items="weekdays"
                    label="Select Days"
                    multiple
                    chips
                    color="primary"
                    prepend-icon="mdi-calendar-today"
                    required
                    :rules="[
                      v => !!v.length || 'please select at least one day'
                    ]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="close">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="submit">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import ApiService from '@/core/services/api.service';

export default {
  props: ["doctorAvails", "allowedStep"],
  data() {
    return {
      dialog: false,
      valid: false,
      selectedDays: this.doctorAvails.days,
      modal_from: false,
      modal_to: false,
      from: this.doctorAvails.from,
      to: this.doctorAvails.to,
      //from_time: this.$moment.utc('2020-01-01T'+this.doctorAvails.from+'Z').tz("Europe/Istanbul").format("HH:mm:ss").toString(),
      //from_edited: this.$moment.utc('2020-01-01T'+this.doctorAvails.from+'Z').tz("Europe/Istanbul").format("hh:mm A").toString(),
      //to_time: this.doctorAvails.to,
      weekdays: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"]
    };
  },
  computed: {
    from_time: {
      get: function() {
        return this.$moment
          .utc("2020-01-01T" + this.from + "Z")
          .tz(localStorage.timezone)
          .format("HH:mm:ss")
          .toString();
      },
      set: function(newValue) {
        //console.log("adusted new selected date", this.$moment.tz('2020-01-01T'+newValue, localStorage.timezone).utc().format("HH:mm:ss").toString())
        //console.log("new selected from date",newValue);
        if (newValue != this.from) {
          this.from = this.$moment
            .tz(
              "2020-01-01T" + newValue,
              localStorage.timezone
            )
            .utc()
            .format("HH:mm:ss")
            .toString();
        }
      }
    },
    to_time: {
      get: function() {
        return this.$moment
          .utc("2020-01-01T" + this.to + "Z")
          .tz(localStorage.timezone)
          .format("HH:mm:ss")
          .toString();
      },
      set: function(newValue) {
        if (newValue != this.to) {
          this.to = this.$moment
            .tz(
              "2020-01-01T" + newValue,
              localStorage.timezone
            )
            .utc()
            .format("HH:mm:ss")
            .toString();
        }
      }
    }
  },
  methods: {
    async submit() {
      if (this.$refs.editForm.validate()) {
        const payload = {
          id: this.doctorAvails.id,
          days: this.selectedDays,
          from: this.from,
          to: this.to
        };
        //console.log(payload);
        this.$emit("submit-edit", payload);
      } else {
        this.toast(
          "b-toaster-top-center",
          "danger",
          "please fill all required fields"
        );
      }
    },
    close() {
      this.$refs.editForm.reset();
      // this.$refs.editForm.reset();
      this.dialog = false;
    },
    toast(toaster, v, msg, append = false) {
      this.counter++;
      this.$bvToast.toast(msg, {
        title: `Alert`,
        toaster: toaster,
        solid: true,
        variant: v,
        appendToast: append
      });
    },
    reloadData() {
      this.selectedDays = this.doctorAvails.days;
      this.from_time = this.doctorAvails.from;
      this.to_time = this.doctorAvails.to;
    }
  }
};
</script>

<style></style>
